import { url } from '@engined/core/services/routes.js';
import { AUTH_LOGIN_ROUTE } from '@asaprint/asap/routes.js';
import { useAuth } from '@asaprint/asap/contexts/AuthContext.js';
import hoistNonReactStatics from 'hoist-non-react-statics';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface AuthenticatedConfig {
  loginUrl?: string;
}

const defaultConfig: AuthenticatedConfig = {
  loginUrl: AUTH_LOGIN_ROUTE,
};

interface OwnProps {
  loginUrl: string;
  children: React.ReactNode;
}

type Props = OwnProps;

let Authenticated: React.FunctionComponent<Props> = ({ loginUrl, children }) => {
  const location = useLocation();
  const { requestUser } = useAuth();
  const destination = url(
    loginUrl,
    {},
    {
      next: `${location.pathname}${location.search}`,
    },
  );

  return <>{!requestUser ? <Navigate to={destination} replace /> : children}</>;
};

Authenticated.displayName = 'Authenticated';

Authenticated = React.memo<Props>(Authenticated);

export { Authenticated };

export default function authenticated<TProps>(
  config: AuthenticatedConfig = {},
): (Component: React.ComponentType<TProps>) => React.ComponentClass<TProps> {
  const options = {
    ...defaultConfig,
    ...config,
  };

  // TODO: Rewrite to function component?
  return (Component: React.ComponentType<TProps>): React.ComponentClass<TProps> => {
    class AuthenticatedComponent extends React.Component<TProps> {
      public static displayName = `authenticated(${Component.displayName || Component.name})`;

      public override render() {
        return (
          <Authenticated loginUrl={options.loginUrl}>
            <Component {...this.props} />
          </Authenticated>
        );
      }
    }

    hoistNonReactStatics(AuthenticatedComponent, Component);

    return AuthenticatedComponent;
  };
}
