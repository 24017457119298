import ConnectedBreadcrumbs from '@asaprint/asap/components/ConnectedBreadcrumbs.js';
import { Box, styled, SxProps, Typography } from '@mui/material';
import React from 'react';

const Root = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
}));

Root.displayName = 'Root';

interface OwnProps {
  className?: string;
  title?: React.ReactNode;
  actions?: React.ReactNode;
  actionsSx?: SxProps;
}

type Props = OwnProps;

const PageHeader: React.FunctionComponent<Props> = ({ className, title, actions, actionsSx }) => {
  if (!title) {
    return null;
  }

  return (
    <Root className={className}>
      <Box flexGrow={actions ? undefined : 1}>
        {title && (
          <Typography variant="h5" component="h1" sx={{ m: 0, fontWeight: 'bold' }}>
            {title}
          </Typography>
        )}
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <ConnectedBreadcrumbs />
      </Box>
      {actions && (
        <Box sx={{ my: { xs: 2, md: 0 }, flexGrow: 1, width: { xs: '100%', md: 'auto' }, ...actionsSx }}>{actions}</Box>
      )}
    </Root>
  );
};

PageHeader.displayName = 'PageHeader';

export default React.memo<Props>(PageHeader);
